const PERCENT_ROUNDING = 100;

// These variables represent the global user state that will be shared across
// the app.
let activityState;
let adobeProfileId;
let daysSinceSyncActivity = -1;
let plan;
let syncLimit = 0;
let syncUsage = 0;

/**
  * Returns a string indicating whether a user is considered active for metrics purposes
  *
  * @returns {String}
  */
export function getActivityState() {
  return activityState;
}

/**
  * Sets a string indicating whether a user is considered active for metrics purposes
  *
  * @param {String} state
  *
  * @returns {String}
  */
export function setActivityState(state) {
  activityState = state;
}

/**
  * Returns a string with the user's Adobe Profile ID
  *
  * @returns {String}
  */
export function getAdobeProfileId() {
  return adobeProfileId;
}

/**
  * Sets a string with the user's Adobe Profile ID
  *
  * @param {String} id
  *
  * @returns {String}
  */
export function setAdobeProfileId(id) {
  adobeProfileId = id;
}

/**
  * Returns days since last sync or unsync user performed, for metrics purposes. Returns -1 if they have no syncs.
  *
  * @returns {Integer}
  */
export function getDaysSinceSyncActivity() {
  return daysSinceSyncActivity;
}

/**
  * Sets an integer representing days since last sync or unsync user performed, for metrics purposes. Should be -1 if they have no syncs.
  *
  * @param {Integer} days
  *
  * @returns {Integer}
  */
export function setDaysSinceSyncActivity(days) {
  daysSinceSyncActivity = days;
}

/**
  * Returns the user's plan name
  *
  * @returns {String}
  */
export function getPlan() {
  return plan || 'unknown';
}

/**
  * Sets the user's plan name
  *
  * @param {String} p
  *
  * @returns {String}
  */
export function setPlan(p) {
  plan = p;
}

/**
  * Returns the current user's sync plan limit
  *
  * @returns {Number}
  */
export function getSyncLimit() {
  return syncLimit;
}

/**
  * Sets the current user's sync plan limit
  *
  * @param {Number} limit
  */
export function setSyncLimit(limit) {
  syncLimit = limit;
}

/**
  * Returns the number of current syncs that are over this users plan limit
  *
  * @returns {Number}
  */
export function getSyncOverageAmount() {
  return Math.max(syncUsage - syncLimit, 0);
}

/**
  * Returns the current user's total number of syncs plus an optional number of fonts
  *
  * @returns {Number}
  */
export function getSyncUsage(numRequested) {
  return syncUsage + (numRequested || 0);
}

/**
  * Returns a percentage indicating the current user's percentage of sync plan used
  * including an optional number of fonts to be requested
  * @param {Number} numRequested
  *
  * @returns {Number}
  */
export function getSyncUsagePercentage(numRequested) {
  if (syncLimit > 0) {
    return Math.floor(((syncUsage + (numRequested || 0)) / syncLimit) * PERCENT_ROUNDING);
  } else {
    return 0;
  }
}

/**
  * Decrements the current user's number of syncs by a specified amount
  *
  * @param {Number} n
  */
export function decrementSyncUsage(n) {
  syncUsage = syncUsage - n;
}

/**
  * Increments the current user's number of syncs by a specified amount
  *
  * @param {Number} n
  */
export function incrementSyncUsage(n) {
  syncUsage = syncUsage + n;
}

/**
  * Returns a boolean indicating whether the user is in overage due to number of current syncs + an optional number
  * of fonts requested
  * @param {Number} numRequested
  *
  * @returns {Boolean}
  */
export function isInOverage(numRequested) {
  return syncUsage + (numRequested || 0) > syncLimit;
}

/**
 * Reset all of the values back to their defaults
 */
export function reset() {
  activityState = undefined;
  adobeProfileId = undefined;
  daysSinceSyncActivity = -1;
  plan = undefined;
  syncLimit = 0;
  syncUsage = 0;
}
